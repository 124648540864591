// 提现相关接口
import service from ".."


// 提现信息
export function withdrawInfo (params) {
  return service({
    method: 'GET',
    url: `/withdraw/info`,
    params
  })
}


// 提现
export function withdrawDeal (params) {
  return service({
    method: 'GET',
    url: `/withdraw/deal`,
    params
  })
}