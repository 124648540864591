// 推广中心相关接口
import service from ".."

// 获取用户数据
export function guessInfo (params) {
  return service({
    method: 'GET',
    url: "/guess/info",
    params
  })
}

// 获取推广说明
export function inviteInfo (params) {
  return service({
    method: 'GET',
    url: "/invite/info",
    params
  })
}

// 获取我的好友
export function inviteFriends (params) {
  return service({
    method: 'GET',
    url: "/invite/friends",
    params
  })
}

// 获取奖励明细
export function invitePrize (params) {
  return service({
    method: 'GET',
    url: "/invite/prize",
    params
  })
}

// 获取推广排行
export function inviteRank (params) {
  return service({
    method: 'GET',
    url: "/invite/rank",
    params
  })
}

// 获取红包列表
export function redList (params) {
  return service({
    method: 'GET',
    url: "/redpacket/lists",
    params
  })
}

// 撤销红包
export function redRevoke (params) {
  return service({
    method: 'GET',
    url: "/redpacket/revoke",
    params
  })
}

// 添加红包
export function redAdd (params) {
  return service({
    method: 'GET',
    url: "/redpacket/add",
    params
  })
}

// 使用红包
export function redDeal (params) {
  return service({
    method: 'GET',
    url: "/redpacket/deal",
    params
  })
}

// 查看红包码
export function redInfo (params) {
  return service({
    method: 'GET',
    url: "/redpacket/info",
    params
  })
}