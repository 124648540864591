// 登录相关接口
import service from ".."

// 获取短信验证码
export function smsSend (params) {
  return service({
    method: 'GET',
    url: "/sms/send",
    params
  })
}

// 获取短信验证码(图形验证码)
export function smsSendByImg (params) {
  return service({
    method: 'GET',
    url: "/sms/send_img",
    params
  })
}

// 注册
export function register (params) {
  return service({
    method: 'GET',
    url: "/ucenter/register",
    params
  })
}


// 登录
export function login (params) {
  return service({
    method: 'GET',
    url: "/ucenter/login",
    params
  })
}


// 忘记密码
export function findPwd (params) {
  return service({
    method: 'GET',
    url: "/ucenter/find_pwd",
    params
  })
}


