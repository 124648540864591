// 任务相关接口
import service from ".."

// 获取每日任务
export function taskDaily (params) {
  return service({
    method: 'GET',
    url: "/task/daily",
    params
  })
}

// 获取新人任务
export function taskNews (params) {
  return service({
    method: 'GET',
    url: "/task/news",
    params
  })
}

// 领取任务奖励
export function dealPrize (params) {
  return service({
    method: 'GET',
    url: "/task/deal_prize",
    params
  })
}