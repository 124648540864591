import { createStore } from 'vuex'
import { indexInfo, logGold, commonInfo, ucenterInfo } from '@/request/api/index.js'
import { getUrlParam, getStylePath } from '@/utils'

export default createStore({
  state: {
    game_index: [],
    game_page: [],
    my_rank: { rank: '', prize: 0 },
    game_alpinia: [],
    goods: [],
    banner_index: {},
    banner_shop: {},
    alpinia_index: [],
    systime: '',
    message: [],
    show_luck: 0,
    alert_msg: '',
    uinfo: { sign: { date: '', person: 0 } },
    bet_auto: '',
    bet_track: '',
    last: {},
    current: {},
    show_play: 0,
    uid: '',
    down_url: ''
  },
  getters: {
  },
  mutations: {
    updateIndexInfo (state, indexInfo) {
      state.banner_index = indexInfo.banner_top[0]
      state.message = indexInfo.notice
      state.game_index = indexInfo.game
      state.uid = indexInfo.uid
      state.down_url = indexInfo.down_url

      state.game_page = indexInfo.game_page
      state.my_rank = indexInfo.my_rank
      state.game_alpinia = indexInfo.game_alpinia
      state.goods = indexInfo.goods
      state.show_luck = indexInfo.show_luck
      state.alert_msg = indexInfo.alert_msg
      state.alpinia_index = indexInfo.alpinia_index
      state.banner_shop = indexInfo.banner_shop
      state.show_play = indexInfo.show_play
    },
    updateUserInfo (state, userInfo) {
      state.uinfo = userInfo
    },
    updateCommonInfo (state, commonInfo) {
      state.bet_auto = commonInfo.bet_auto
      state.bet_track = commonInfo.bet_track
      state.last = commonInfo.last
      state.current = commonInfo.current
      state.systime = commonInfo.systime
    },
    setCurrentTime (state) {
      state.current.remain_time = state.current.remain_time - 1
      state.current.open_limit_time = state.current.open_limit_time - 1
    }
  },
  actions: {
    async getIndexInfo (context, callback) {
      const res = await indexInfo({ type: 2 })
      if (res.code == 0) {
        callback && callback(res.data)
        context.commit('updateIndexInfo', res.data)
      }
    },
    async getUserInfo (context, callback) {
      const res = await ucenterInfo()
      if (res.code == 0) {
        callback && callback(res.data)
        context.commit('updateUserInfo', res.data)
      }
    },

    async getCommonInfo (context, callback) {
      const res = await commonInfo({
        stylePath: getStylePath(getUrlParam('style'))
      })
      if (res.code == 0) {
        callback && callback(res.data)
        context.commit('updateCommonInfo', res.data)
      }
    }
  },
  modules: {
  }
})
