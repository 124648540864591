// 个人中心相关接口
import service from ".."
import axios from 'axios'

// 获取银鱼记录
export function logGold (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_gold",
    params
  })
}


// 执行签到
export function signDeal (params) {
  return service({
    method: 'GET',
    url: "/sign/deal",
    params
  })
}

// 存储银鱼
export function saveGold (params) {
  return service({
    method: 'GET',
    url: "/ucenter/save_gold",
    params
  })
}

// 提取银鱼
export function dislodgeGold (params) {
  return service({
    method: 'GET',
    url: "/ucenter/dislodge_gold",
    params
  })
}

// 使用银鱼卡
export function cardDeal (params) {
  return service({
    method: 'GET',
    url: "/card/deal",
    params
  })
}

// 退出登录
export function loginOut (params) {
  return service({
    method: 'GET',
    url: "/ucenter/login_out",
    params
  })
}

// 修改密码
export function resetPwdByPhone (params) {
  return service({
    method: 'GET',
    url: "/ucenter/reset_pwd_by_phone",
    params
  })
}


// 修改支付密码
export function setPayPwd (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_pay_pwd",
    params
  })
}

// 上传图片
export function fileUpload (params) {
  return axios({
    headers: {
      'Content-Type': 'multipart/form-data;'
    },
    method: 'post',
    url: 'https://i.tiaotiaozhu.com/file/upload',
    data: params
  })
  // return service({
  //   headers: {
  //     'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarykIiSHD7lv8eGG579'
  //   },
  //   method: 'POST',
  //   url: "/file/upload",
  //   params
  // })
}

// 设置昵称
export function setUname (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_uname",
    params
  })
}

// 银鱼卡管理
export function cardLogs (params) {
  return service({
    method: 'GET',
    url: "/card/logs",
    params
  })
}


// 实名认证
export function auth (params) {
  return service({
    method: 'GET',
    url: "/ucenter/auth",
    params
  })
}



// 支付密码确认
export function checkPayPwd (params) {
  return service({
    method: 'GET',
    url: "/ucenter/check_pay_pwd",
    params
  })
}



// 用户信息
export function ucenterInfo (params) {
  return service({
    method: 'GET',
    url: "/ucenter/info",
    params
  })
}



// 绑定支付宝
export function bindAlipay (params) {
  return service({
    method: 'GET',
    url: "/ucenter/ali_auth",
    params
  })
}


// 金鱼明细
export function logDiamond (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_diamond",
    params
  })
}


// 兑换信息
export function exchangeInfo (params) {
  return service({
    method: 'GET',
    url: "/exchange/info",
    params
  })
}


// 兑换
export function exchangeDeal (params) {
  return service({
    method: 'GET',
    url: `/exchange/deal`,
    params
  })
}


// 抽奖记录
export function luckyLog (params) {
  return service({
    method: 'GET',
    url: "/lucky/log",
    params
  })
}


// 银鱼卡
export function cardRule (params) {
  return service({
    method: 'GET',
    url: "/card/rule",
    params
  })
}


// 系统头像
export function defaultHead (params) {
  return service({
    method: 'GET',
    url: "/ucenter/default_head",
    params
  })
}


// 设置头像
export function setHead (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_head",
    params
  })
}