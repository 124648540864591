// VIP相关接口
import service from ".."

// 获取首页数据
export function vipIndex (params) {
  return service({
    method: 'GET',
    url: "/vip/index_mobile",
    params
  })
}

// 确定充值
export function vipDealBuy (params) {
  return service({
    method: 'GET',
    url: "/vip/deal_buy",
    params
  })
}