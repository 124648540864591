import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, {
      name: _ctx.$router.customRouterData.transitionName
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2
    }, 1032, ["name"])]),
    _: 1
  });
}