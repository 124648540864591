// 首页相关接口
import service from ".."

// 获取益智娱乐数据
export function gameIndexInfo (params) {
  return service({
    method: 'GET',
    url: "/game/index_info",
    params
  })
}

// 获取竞猜工资
export function guessWages (params) {
  return service({
    method: 'GET',
    url: "/guess/wages",
    params
  })
}

// 领取竞猜工资
export function guessDeal (params) {
  return service({
    method: 'GET',
    url: "/guess/deal_wages",
    params
  })
}

// 获取救济银鱼
export function guessRelief (params) {
  return service({
    method: 'GET',
    url: "/guess/relief",
    params
  })
}

// 领取救济银鱼
export function reliefDeal (params) {
  return service({
    method: 'GET',
    url: "/guess/deal_relief",
    params
  })
}
