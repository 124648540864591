// 兑换相关接口
import service from ".."


// 兑换列表
export function shopIndex (params) {
  return service({
    method: 'GET',
    url: `/shop/index`,
    params
  })
}


// 兑换
export function shopDeal (params) {
  return service({
    method: 'GET',
    url: `/shop/deal`,
    params
  })
}


// 兑换记录
export function shopLists (params) {
  return service({
    method: 'GET',
    url: `/shop/lists`,
    params
  })
}