// 引入扩展函数
import transitionExtend from "./transition-extend"
import { createRouter, createWebHistory } from 'vue-router'
const MenuLayout = () => import('../views/MenuLayout/index.vue')
const Home = () => import('../views/Home/index.vue')
const Fun = () => import('../views/Fun/index.vue')
const Task = () => import('../views/Task/index.vue')
const GuessWages = () => import('../views/GuessWages/index.vue')
const Relief = () => import('../views/Relief/index.vue')
const Register = () => import('../views/Register/index.vue')
const ChangeDetail = () => import('../views/ChangeDetail/index.vue')
const LuckyDetail = () => import('../views/LuckyDetail/index.vue')
const AvatarSet = () => import('../views/AvatarSet/index.vue')
const RealName = () => import('../views/RealName/index.vue')
const PlayRank = () => import('../views/PlayRank/index.vue')
const PlayRankRule = () => import('../views/PlayRankRule/index.vue')


const Play = () => import('../views/Play/index.vue')
const Change = () => import('../views/Change/index.vue')
const ChangeSubmit = () => import('../views/ChangeSubmit/index.vue')
const UCenter = () => import('../views/UCenter/index.vue')
const GoldDetail = () => import('../views/GoldDetail/index.vue')
const MyOrder = () => import('../views/MyOrder/index.vue')
const Safe = () => import('../views/Safe/index.vue')
const UnameSet = () => import('../views/UnameSet/index.vue')
const ChangePass = () => import('../views/ChangePass/index.vue')
const UseCard = () => import('../views/UseCard/index.vue')
const Extend = () => import('../views/Extend/index.vue')
const ExtendRank = () => import('../views/ExtendRank/index.vue')
const ExtendRankRule = () => import('../views/ExtendRankRule/index.vue')
const Vip = () => import('../views/Vip/index.vue')
const Login = () => import('../views/Login/index.vue')
const Forget = () => import('../views/Forget/index.vue')
const Lucky = () => import('../views/LuckDraw/index.vue')
const LuckyPage = () => import('../views/LuckyPage/index.vue')
const Crazy = () => import('../views/Crazy/Index/index.vue')
const JoinRecord = () => import('../views/Crazy/JoinRecord/index.vue')
const PrizeDetail = () => import('../views/Crazy/PrizeDetail/index.vue')
const CrazyRank = () => import('../views/Crazy/CrazyRank/index.vue')
const RankRule = () => import('../views/Crazy/RankRule/index.vue')
const AutoJoin = () => import('../views/Crazy/AutoJoin/index.vue')
const AutoAdd = () => import('../views/Crazy/AutoAdd/index.vue')
const ModeDetail = () => import('../views/Crazy/ModeSet/detail/index.vue')
const ModeIndex = () => import('../views/Crazy/ModeSet/index/index.vue')
const Trend = () => import('../views/Crazy/Trend/index.vue')
const Join = () => import('../views/Crazy/Join/index.vue')
const CrazyIntro = () => import('../views/Crazy/CrazyIntro/index.vue')
const MyCards = () => import('../views/MyCards/index.vue')
const WithDraw = () => import('../views/WithDraw/index.vue')
const BindAliPay = () => import('../views/BindAliPay/index.vue')
const ExChange = () => import('../views/ExChange/index.vue')
const DiamondDetail = () => import('../views/DiamondDetail/index.vue')

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: MenuLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/play',
        name: 'play',
        component: Play
      },
      {
        path: '/fun',
        name: 'fun',
        component: Fun
      },
      {
        path: '/change',
        name: 'change',
        component: Change
      },
      {
        path: '/ucenter',
        name: 'ucenter',
        component: UCenter
      },
      {
        path: '/extend',
        name: 'extend',
        component: Extend
      }
    ]
  },
  {
    path: '/task',
    name: 'task',
    component: Task
  },
  {
    path: '/changeSub',
    name: 'changeSub',
    component: ChangeSubmit
  },
  {
    path: '/goldDetail',
    name: 'goldDetail',
    component: GoldDetail
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: MyOrder
  },
  {
    path: '/safe',
    name: 'safe',
    component: Safe
  },
  {
    path: '/unameSet',
    name: 'unameSet',
    component: UnameSet
  },
  {
    path: '/changepass',
    name: 'changepass',
    component: ChangePass
  },
  {
    path: '/usecard',
    name: 'usecard',
    component: UseCard
  },
  {
    path: '/vip',
    name: 'vip',
    component: Vip
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forget',
    name: 'forget',
    component: Forget
  },
  {
    path: '/lucky',
    name: 'lucky',
    component: Lucky
  },
  {
    path: '/luckyPage',
    name: 'luckyPage',
    component: LuckyPage
  },
  {
    path: '/home28',
    name: 'home28',
    component: Crazy
  },
  {
    path: '/joinRecord',
    name: 'joinRecord',
    component: JoinRecord
  },
  {
    path: '/prizeDetail',
    name: 'prizeDetail',
    component: PrizeDetail
  },
  {
    path: '/crazyRank',
    name: 'crazyRank',
    component: CrazyRank
  },
  {
    path: '/rankRule',
    name: 'rankRule',
    component: RankRule
  },
  {
    path: '/autoJoin',
    name: 'autoJoin',
    component: AutoJoin
  },
  {
    path: '/modeDetail',
    name: 'modeDetail',
    component: ModeDetail
  },
  {
    path: '/autoAdd',
    name: 'autoAdd',
    component: AutoAdd
  },
  {
    path: '/modeIndex',
    name: 'modeIndex',
    component: ModeIndex
  },
  {
    path: '/trend',
    name: 'trend',
    component: Trend
  },
  {
    path: '/join',
    name: 'join',
    component: Join
  },
  {
    path: '/crazyIntro',
    name: 'crazyIntro',
    component: CrazyIntro
  },
  {
    path: '/mycards',
    name: 'mycards',
    component: MyCards
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: WithDraw
  },
  {
    path: '/bindAliPay',
    name: 'bindAliPay',
    component: BindAliPay
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: ExChange
  },
  {
    path: '/diamondDetail',
    name: 'diamondDetail',
    component: DiamondDetail
  },
  {
    path: '/extendRank',
    name: 'extendRank',
    component: ExtendRank
  },
  {
    path: '/extendRankRule',
    name: 'extendRankRule',
    component: ExtendRankRule
  },
  {
    path: '/guessWages',
    name: 'guessWages',
    component: GuessWages
  },
  {
    path: '/relief',
    name: 'relief',
    component: Relief
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/changeDetail',
    name: 'changeDetail',
    component: ChangeDetail
  },
  {
    path: '/luckyDetail',
    name: 'luckyDetail',
    component: LuckyDetail
  },
  {
    path: '/avatarSet',
    name: 'avatarSet',
    component: AvatarSet
  },
  {
    path: '/realName',
    name: 'realName',
    component: RealName
  },
  {
    path: '/playRank',
    name: 'playRank',
    component: PlayRank
  },
  {
    path: '/playRankRule',
    name: 'playRankRule',
    component: PlayRankRule
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})


export default transitionExtend(router)
