// 试玩相关接口
import service from ".."

// 获取试玩榜单
export function gameRank (params) {
  return service({
    method: 'GET',
    url: "/game/index",
    params
  })
}